<template>
  <div>
    <div class="header-text">
      <h3>Total User</h3>
      <h4>{{ totalUser }}</h4>
    </div>
    <div class="pt-3">
      <h4 style="position: relative;left:40px;">User Information</h4>

      <div v-if="Object.keys(userInfo).length>0 " class="card-group">

        <div v-for="(item, sectionName) in userInfo"
        :key="sectionName"
        v-if="sectionName !== 'totalUser'"
        class="card">

          <div class="card-header">
            {{ sectionName }}
          </div>
          <div class="text-justify">
            <div class="card-body">

              <template>
                <span class="text-justify  text-left" style="list-style: none;">
                  <!-- <li>
                    <i class="fa fa-shopping-cart mr-2"></i>&nbsp;
                    <b>{{ item.social_user }}</b>
                   New Social User
                  </li> -->
                  <li>
                    <i class="fa fa-user-circle mr-2"></i>&nbsp;
                    <b>{{ item.without_social_user }}</b>
                   New System User
                  </li>
                  <li>
                    <i class="fa fa-check mr-2"></i>&nbsp;
                    <b>{{ item.guest_user }} </b>
                   New Guest User
                  </li>
                  <li v-if="sectionName === 'Today' || sectionName === 'Previous Day'">
                    <i class="fa fa-unlock mr-2"></i>&nbsp;
                    <b >{{ item.active_user }}</b>
                    Active User
                  </li>


                </span>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
         
        Loading...
      </div>







    </div>
  </div>
</template>

<script>
import { sendRequest } from "@/http/axios.method";

// Counter Widgets
import WidgetCounter from "../components/Widgets/WidgetCounter";

export default {
  components: {
    WidgetCounter,
  },
  data() {
    return {
      config: {
        Authorization: "Bearer " + this.$store.state.authUser.token,
      },
      dashboardReports: [],
     
      userInfo: {
            "Today": {
              "social_user": 0,
                "without_social_user": 0,
                "guest_user": 0,
                "active_user": 0
            },
            "Previous Day": {
              "social_user": 0,
                "without_social_user": 0,
                "guest_user": 0,
                "active_user": 0
            },
            "This Month": {
              "social_user": 0,
                "without_social_user": 0,
                "guest_user": 0,
                "active_user": 0
            },
            "Last Month": {
              "social_user": 0,
                "without_social_user": 0,
                "guest_user": 0,
                "active_user": 0
            }
          },
      totalUser:0,
      confirmLoading: false,
    };
  },
  mounted() {
    this.dashboardReport();
  },
  methods: {
    dashboardReport() {
      this.confirmLoading = true;
      sendRequest("get", "admin/get-dashbaord-data", {}, this.config)
        .then((response) => {
          this.confirmLoading = false;
          if (response.success) {
            let responses = response.data;
 
              this.totalUser = responses.totalUser;
              this.userInfo = responses;
            
             
           

          } else {
            if (response.status_code === 401) {
              this.$store.dispatch("logout");
              this.$router.push("/login");
              
            }
            if (response.status_code === 500) {
              this.revenueReportData = [];
            }
          }
        })
        .catch(() => {
          this.confirmLoading = false;
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
  },

};
</script>

<style lang="scss">
.header-text {
  text-align: center;
  outline: 1px solid  darkgrey;
  max-width: 30%;
  border-radius: 16px;
  position: relative;
  left: 40px;
  margin-bottom: 50px;
   
}

.header-text h3{

   background-color: #03AB77;
   border-top-right-radius: 16px;
   border-top-left-radius: 16px;
   color: #fff;
}

.header-text h4{
padding-bottom: 15px;
}

.card-group{
  display: flex;
  flex-wrap: wrap;
  justify-content:space-around;
  margin-top: 30px;
}

.card{
  outline: 1px solid  darkgrey;
  border-radius: 16px;
 
 }
.card-header{
  text-align: center;
  background-color: #03AB77;
  color: #fff;
  height: 50px;
  padding-top: 10px;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}

li {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1rem;
}



 


.card {
  font-size: 16px;
  font-weight: bold;
}
  
.text-justify {
  list-style: none;
  padding: 0 1rem;
  flex-grow: 1;
}
.card-body {
  display: block;
  height: 100%;
  
}
 
</style>

